import React from "react"
import Header from "../components/header"
import LayoutCentre from "../components/layout-centre"
import AppStoreLinks from "../components/app-store-links"
import Video from "../components/video"
import layoutStyles from "../components/layout.module.scss"

import { Helmet } from "react-helmet"

// import { graphql } from "gatsby"
import Img from "gatsby-image"

const ScreenShot = props => (
    <li>
        <a href={props.link}>
            <Img fluid={props.img} alt="{props.alt}" />
        </a>
    </li>
  )

export default ({ data }) => (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>BestBitGames</title>
            <meta name="description" content="Game developer based in Bristol UK." />
            <link rel="canonical" href="http://www.bestbitgames.co.uk" />
        </Helmet>

        {/* <h1>About {data.site.siteMetadata.title}</h1> */}
 
 {/* todo - change haeder image to graphql queries! */}
    
        <LayoutCentre backgroundImage={require("../images/gradient.png")} bgColor="#0e0c25">
           
            <Header headerText="BestBitGames" logo={require("../images/logo.png")}/>
        
            
            {/*
            <p>Press page.</p>

            <p>My Pet Reindeer stuff.</p>

        

            <p>Each game / app is seperated by a gradient background. Components for Each
                app are 
                maybe social buttons.
            </p> */}

        </LayoutCentre>
        {/* border-top: 50px solid rgb(108, 25, 20); */}
        <LayoutCentre idName="blood-sport" backgroundImage={require("../images/gradient-bs.png")} bgColor="#161616" id="blood-sport">
            <Header headerText="Blood Sport" logo={require("../images/blood-sport-logo.png")}/>

            <AppStoreLinks
                androidUrl="https://play.google.com/store/apps/details?id=m.BestBitGames.BloodSport"
                iosURL=""
                windowsURL="https://www.microsoft.com/en-us/p/blood-sport/9nblggh4njx8">
            </AppStoreLinks>
            
            <p style={{ textAlign: 'center' }}>Blood Sport is an explosive combination of rugby, football and fighting. Battle to the death in each match, win by goals scored or by headless victory! Can you make it to the top of the Blood Sports league mode?</p>
            <p style={{ textAlign: 'center' }}>Collect the glowing coloured squares on the pitch to effect the gameplay. Want to run faster than your opponent, freeze the entire opposition team or increase your health? Just watch out for the instant knockout modifier!</p>

            <h4>Trailer</h4>
            <Video></Video>

            <h4>Screenshots</h4>
            <ul className={layoutStyles.screenshots}>
                <ScreenShot link={data.bloodSportScreenshot1.publicURL} img={data.bloodSportScreenshot1.childImageSharp.fluid} alt="Blood Sport Screenshot 1"></ScreenShot>
                <ScreenShot link={data.bloodSportScreenshot2.publicURL} img={data.bloodSportScreenshot2.childImageSharp.fluid} alt="Blood Sport Screenshot 2"></ScreenShot>
                <ScreenShot link={data.bloodSportScreenshot3.publicURL} img={data.bloodSportScreenshot3.childImageSharp.fluid} alt="Blood Sport Screenshot 3"></ScreenShot>
                {/* <ScreenShot link={data.bloodSportScreenshot4.publicURL} img={data.bloodSportScreenshot4.childImageSharp.fluid} alt="Blood Sport Screenshot 4"></ScreenShot> */}
                <ScreenShot link={data.bloodSportScreenshot5.publicURL} img={data.bloodSportScreenshot5.childImageSharp.fluid} alt="Blood Sport Screenshot 5"></ScreenShot>
            </ul>
        </LayoutCentre>

        <LayoutCentre>
            <div>
                <h4>Contact</h4>

                <form action="https://formspree.io/contact@bestbitgames.co.uk" method="POST">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="name" />

                    <label htmlFor="email">Email</label>
                    <input type="email" name="_replyto" id="email"/>

                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" required />

                    <button type="submit">Submit</button>

                    <input type="hidden" name="_next" value="https://www.bestbitgames.co.uk" />

                </form>
            </div>
        </LayoutCentre>
    </div>
)

export const screenshotImage = graphql`
  fragment screenshotImage on File {
    childImageSharp {
      fluid(maxWidth: 450) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const appIconImage = graphql`
  fragment appIconImage on File {
    childImageSharp {
      fluid(maxWidth: 150) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    bloodSportScreenshot1: file(relativePath: { eq: "blood-sport-screenshot-01.jpeg" }) {
        ...screenshotImage,
        publicURL
    },
    bloodSportScreenshot2: file(relativePath: { eq: "blood-sport-screenshot-02.jpeg" }) {
        ...screenshotImage,
        publicURL
    },
    bloodSportScreenshot3: file(relativePath: { eq: "blood-sport-screenshot-03.jpeg" }) {
        ...screenshotImage,
        publicURL
    },
    bloodSportScreenshot4: file(relativePath: { eq: "blood-sport-screenshot-04.jpeg" }) {
        ...screenshotImage,
        publicURL
    },
    bloodSportScreenshot5: file(relativePath: { eq: "blood-sport-screenshot-05.jpeg" }) {
        ...screenshotImage,
        publicURL
    },
    site {
        siteMetadata {
          title
        }
      }
  }
`